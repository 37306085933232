<template>
  <div class="agent-form">
    <el-header>
      <div
        :style="{color:currentTheme.logoTextColor}"
        class="logo-container">
        <img
          src="@/assets/images/logo1.png"
          alt="">
        <span>Bunker Chain</span>
      </div>
      <!-- <div class="top-container">
        <div class="right">
          语言切换
          <div
            class="top-block">
            <language-selector :style="{color:currentTheme.barTextColor}">
            </language-selector>
          </div>
        </div>
      </div> -->
    </el-header>
    <div class="container" v-if="isValid">
      <div class="email-detail">
        <!-- <p>Date: {{orderItem.createDate}}</p> -->
        <p>Dear Sirs,</p>
        <!-- <p class="main-text">
          Please fill in the following information to complete the registration.
        </p> -->
      </div>
      <p class="one-tip">Please fill in the following information to complete the registration.</p>
      <el-form ref="userForm" label-position="top" label-width="230px" :model="userForm" :rules="rules">
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item label="Email" prop="email">
              <el-input v-model="userForm.email" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Salutation" prop="salutation">
              <el-select
                style="width: 100%"
                v-model="userForm.salutation">
                <el-option
                  v-for="item in ['Mr.','Mrs.','Miss','Dr.','Ms.','Prof.','Rev.','Lady.','Sir.','Capt.']"
                  :key="item"
                  :value="item">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item label="First Name" prop="firstName">
              <el-input v-model="userForm.firstName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Last Name" prop="lastName">
              <el-input v-model="userForm.lastName"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item label="Password" prop="password">
              <el-input type="password" v-model="userForm.password" show-password></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Confirm password" prop="confirmPassword">
              <el-input type="password" v-model="userForm.confirmPassword" show-password></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item label="H/p Numbers" prop="homePhoneNumber">
              <phone-input v-model="userForm.homePhoneNumber"></phone-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Office Phone Number" prop="officePhoneNumber">
              <phone-input v-model="userForm.officePhoneNumber"></phone-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="40">
          <el-col :span="24">
            <el-form-item label="Designation" prop="designation">
              <el-input v-model="userForm.designation"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="btns">
        <el-button class="btn" @click="resetForm">{{ $t('reset') }}</el-button>
        <el-button class="btn" type="primary" :loading="submitLoading" @click="submitForm">submit</el-button>
      </div>
      <p class="one-tip">Many thanks and have a great day ahead !</p>
      <p class="one-tip">By uploading and accepting the conditions or uploading any documents, you confirm and agree to
        the binding usage of Digital agreements of Bunkerchain Pte Ltd, if you have received this e-mail by mistake,
        please inform the user immediately or send a technical request to support@bunkerchain.io. Bunkerchain Pte Ltd
        reserve all rights, for a copy of our terms & conditions, please visit our website at www.bunkerchain.io, or
        request with the user administrator. Please note that any views or opinions presented in this email are solely
        those of the author and do not necessarily represent those of the company. Finally, the recipient should check
        this email and any attachments for the presence of viruses. The company accepts no liability for any damage
        caused by any virus transmitted by this email.</p>
    </div>
    <div v-else class="no-valid">
      {{ $t('pageExpired') }}
    </div>
  </div>
</template>

<script>
import baseTableMixin from '@/mixins/base-table-mixin'
import PhoneInput from '@/components/PhoneInput'
// import EmailVerificationInput from '@/components/EmailVerificationInput'
import { mapState } from 'vuex'

export default {
  mixins: [baseTableMixin],
  components: { PhoneInput },
  data () {
    return {
      isValid: true,
      userForm: {},
      resEmail: '',
      submitLoading: false,
      rules: {
        // salutation: [
        //   { required: true, message: 'Please enter salutation', trigger: 'blur' }
        // ],
        password: [
          { required: true, message: 'Please enter password', trigger: 'blur' },
          {
            pattern: /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*(-)_+=~`]).{8,30})/,
            message: 'Password at least one number, uppercase, lowercase, special characters, 8 to 30 bits in length'
          }
        ],
        confirmPassword: [
          { required: true, message: 'Please confirm password', trigger: 'blur' }
        ],
        firstName: [
          { required: true, message: 'Please enter first name', trigger: 'blur' }
        ],
        lastName: [
          { required: true, message: 'Please enter last name', trigger: 'blur' }
        ],
        homePhoneNumber: [
          { required: true, message: 'Please enter H/p Numbers', trigger: 'blur' }
        ],
        email: [
          { required: true, message: 'Please verify the email', trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    ...mapState({
      currentTheme: 'currentTheme'
    })
  },
  methods: {
    getParamByUUid () {
      this.$request.post({
        url: this.$apis.getInviteUserParamByUUid,
        data: {
          uuid: this.$route.query.uuid || ''
        }
      }).then(res => {
        if (res?.code === 1000) {
          this.resEmail = res.data.email || ''
          this.userForm = Object.assign({}, this.userForm, {
            email: this.resEmail
          })
        } else {
          this.isValid = false
          // this.$message.error(res.message)
        }
      })
    },

    submitForm () {
      this.$refs.userForm.validate((valid) => {
        if (valid) {
          if (this.userForm.password !== this.userForm.confirmPassword) {
            this.$message.error('Password does not match!')
            return false
          }
          this.submitLoading = true
          this.$request.post({
            url: this.$apis.saveInviteUserRegister,
            data: {
              uuid: this.$route.query.uuid || '',
              user: Object.assign({}, this.userForm, {
                name: this.userForm.firstName + ' ' + this.userForm.lastName,
                username: this.userForm.firstName + ' ' + this.userForm.lastName
              })
            }
          }).then(res => {
            if (res?.code === 1000) {
              this.$message.success('Register was successful!')
              this.$router.replace('/login')
            } else {
              this.$message.error(res.message)
            }
          }).finally(() => {
            this.submitLoading = false
          })
        } else {
          this.$message.error('Please complete the form information!')
          return false
        }
      })
    },
    resetForm () {
      this.$refs.userForm.resetFields()
      this.userForm = Object.assign({}, this.userForm, {
        email: this.resEmail
      })
    }
  },
  mounted () {
    this.getParamByUUid()
    this.$i18n.locale = 'en'
    localStorage.language = 'en'
  }
}

</script>

<style lang="scss">
.agent-form {
  body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, fieldset, input, p, blockquote, table, th, td, embed, object {
    padding: 0;
    margin: 0;
  }

  p {
    padding: 3px 0;
    color: #909399;
  }

  .el-header {
    @include flex-center-row;
    padding: 0;
    justify-content: flex-start;
    background-color: $primary-color;

    .logo-container {
      @include flex-center-row;
      box-sizing: border-box;
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      box-shadow: 1px 0 2px 0 rgba(0, 0, 0, .05);
      padding-left: 20px;

      img {
        height: 30px;
      }

      span {
        margin-left: 20px;
        white-space: nowrap
      }
    }
  }

  .container {
    width: 1200px;
    min-height: calc(100vh - 55px);
    box-sizing: border-box;
    margin: 0 auto;
    padding: 10px 25px;
    background-color: #F4F6FC;
    padding-bottom: 50px;

    .el-form--label-top .el-form-item__label {
      padding: 0 0 0 10px;
    }

    .el-form-item {
      margin-bottom: 12px;

      .el-form-item__error {
        padding: 5px;
      }

      .el-input__inner {
        padding: 5px 8px;
      }
    }

    .main-text {
      padding: 20px 0;
      color: #303133;
    }

    .order-info {
      margin-bottom: 20px;

      p {
        display: flex;

        .label {
          width: 40%;
          padding-left: 30px;
          box-sizing: border-box;
        }
      }
    }

    .el-card.box-card {
      border: 0;
      position: relative;
      overflow: unset;
      border-radius: 10px;
      margin-top: 25px;
      background-color: #fff;

      &.is-always-shadow, &.is-hover-shadow:focus, &.is-hover-shadow:hover {
        box-shadow: 0 0 0 0;
      }

      .el-card__header {
        padding: 0;
        border-bottom: 0;
        display: flex;

        .clearfix {
          position: relative;
          padding: 5px 10px;
          font-size: 13px;
          font-weight: 700;
          background-color: #3e95bf;
          border-radius: 5px 0 5px 0;
          color: #fff;
          box-shadow: 1px 2px 5px #ccc;
          z-index: 1;
          margin-top: -1px;
          margin-left: -1px;
        }
      }

      .el-card__body {
        padding: 15px 20px;

        .el-upload {
          width: 100%;

          .upload-btn {
            width: 100%;
            padding: 10px 15px;
          }
        }
      }
    }

    .one-tip {
      padding: 25px 10px;
    }

    .btns {
      display: flex;
      justify-content: center;

      .btn {
        margin: 30px 20px 20px 25px;
        padding: 11px 100px;
        font-size: 16px;
      }
    }
  }

  .no-valid {
    text-align: center;
    padding: 40px;
    font-size: 58px;
    font-weight: 400;
    color: #C0C4CC;
    letter-spacing: 5px;
  }
}
</style>
